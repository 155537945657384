@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
@import "colors"
*
  box-sizing: border-box
  padding: 0
  margin: 0

.disabled
  pointer-events: none
  opacity: 0.2

.disable
  pointer-events: none

.not-display
  visibility: hidden

.blur-bg
  -webkit-filter: blur(3px)
  -moz-filter: blur(3px)
  -o-filter: blur(3px)
  -ms-filter: blur(3px)
  filter: blur(3px)

.max-width-container
  width: 100vw
  margin: 0 auto
  @media screen and (min-width: 1024px)
    max-width: 850px

body
  background: #FFFFFF

.app
  max-width: 800px
  margin-top: 0px
  margin-right: auto
  margin-bottom: 0px
  margin-left: auto
  font-family: IBM Plex Sans


.a-left
  text-align: left
.a-right
  text-align: right
.a-center
  text-align: center

.row
  padding: 8px


button
  &:focus
    -webkit-box-shadow: none
    box-shadow: none
