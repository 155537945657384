.loading {
    top: 0;
    left: 0;
    z-index: 200;
    position: fixed;
    width: 100%;
    height: 100%; }
.loading:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/images/progress_indicator.png) no-repeat center/100px;
    -webkit-animation-name: loader;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 3s;
    /* Safari 4.0 - 8.0 */
    animation-name: loader;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite; }

@keyframes loader {
    from {
        transform: rotate(0deg); }
    to {
        transform: rotate(360deg); } }